import { FaThumbsUp, FaThumbsDown, FaBookBookmark } from "react-icons/fa6";
import { useState } from "react";
import { ImBookmarks } from "react-icons/im";
import { FaEye, FaTags } from "react-icons/fa";
import { AiFillTrademarkCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

import "./Book.css";
import Footer from "../../components/layout/footer/Footer";
import Header from "../../components/layout/header/Header";
import image1 from "../../../assets/home__hero__image.png";
import image2 from "../../../assets/images/book-gift.png";
import coin from "../../../assets/icons/coin.png";
import gift1 from "../../../assets/icons/gift1.png";
import gift2 from "../../../assets/icons/gift2.png";
import gift3 from "../../../assets/icons/gift3.png";
import gift4 from "../../../assets/icons/gift4.png";
import gift5 from "../../../assets/icons/gift5.png";
import gift6 from "../../../assets/icons/gift6.png";
import gift7 from "../../../assets/icons/gift7.png";
import gift8 from "../../../assets/icons/gift8.png";
import image4 from "../../../assets/image10.png";
import { BsStarFill } from "react-icons/bs";
import Modal from "../../../common-components/modal/Modal";

function Book() {
  const navigate = useNavigate();
  const average = 4;
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });
  const [isOpen, setIsOpen] = useState({
    review: false,
    gift: false,
  });
  const closeModal = () => {
    setIsOpen({
      review: false,
      gift: false,
    });
  };
  const handleModalClick = (option) => {
    option === "review"
      ? setIsOpen((prev) => ({ ...prev, gift: false, review: true }))
      : setIsOpen((prev) => ({ ...prev, gift: true, review: false }));
  };

  return (
    <div className="book__bg">
      <div className="book">
        <Header />
        <div className="book__section__one">
          <img src={image1} alt="" />
          <div className="book__section__one__end">
            <h1>Almost perfect partner</h1>
            <span>
              <FaBookBookmark />
              <h3> Romance</h3>
            </span>
            <span>
              <ImBookmarks />
              <h3>1,255 Chapters</h3>
            </span>
            <span>
              <AiFillTrademarkCircle />
              <h3>18+</h3>
            </span>
            <span>
              <FaTags />
              <h3>Shally Popy</h3>
            </span>
            <span>
              <FaEye />
              <h3>18,307 Views</h3>
            </span>

            <span className="book__section__one__end__cta">
              <button
                className="book__section__one__end__cta__bt read"
                onClick={() => navigate("/book-page")}
              >
                READ
              </button>
              <button className="book__section__one__end__cta__bt add">
                ADD TO SHELF
              </button>
              <button className="book__section__one__end__cta__bt like">
                <FaThumbsUp />
                Like
              </button>
            </span>
          </div>
        </div>
        <div className="book__section__two">
          <span className="book__section__two__menu">
            <button
              className={
                select.select1
                  ? "book__section__two__menu__button--active"
                  : "book__section__two__menu__button"
              }
              onClick={() =>
                setSelect((prevState) => ({
                  ...prevState,
                  select1: true,
                  select2: false,
                }))
              }
            >
              About
            </button>
            <button
              className={
                select.select2
                  ? "book__section__two__menu__button--active"
                  : "book__section__two__menu__button"
              }
              onClick={() =>
                setSelect((prevState) => ({
                  ...prevState,
                  select1: false,
                  select2: true,
                }))
              }
            >
              Catalog
            </button>
          </span>
          <>
            {select.select1 && (
              <div>
                <div className="book__section__two__synopsis">
                  <h1>Synopsis</h1>
                  <p>
                    After recent divorcée Julie has sworn off romance to focus
                    on her marketing career, her friends finally convince her to
                    cautiously dip a toe back in by trying online dating. To
                    Julie's surprise, she seems to hit it off with Blake, a
                    charming architect. But over time, red flags appear
                    revealing that Blake is still secretly obsessed with and
                    harboring hopes to reunite with his ex, Lila. When
                    devastated Julie discovers his "hidden interest" was a
                    relative he actually dated the entire time they were
                    together, she has to call upon the support of friends to
                    gain courage she didn't know she had to stand up for
                    herself. The story provides twists on an age-old question -
                    should you follow your head or your heart? Julie's journey
                    forces her to risk being hurt again to find who is genuine
                    while untangling lies, hidden intentions, and confronting
                    betrayal head-on.
                  </p>
                </div>
                <div className="book__section__two__tags">
                  <h1>Tags</h1>
                  <span>
                    <button>Love</button>
                    <button>Unite</button>
                    <button>wife</button>
                    <button>Divorce</button>
                    <button>Romance</button>
                    <button>Humor</button>
                    <button>Love</button>
                    <button>Love</button>
                  </span>
                </div>
                <div className="book__section__two__gifts">
                  <h1>Gifts</h1>
                  <span className="book__section__two__gifts__span">
                    <span>
                      <img alt="" src={image2} /> <h1>12</h1>
                      <p>recevied</p>
                    </span>
                    <button onClick={() => handleModalClick("gift")}>
                      GIFT AUTHOR
                    </button>
                  </span>
                </div>
                <div className="book__section__two__ratings">
                  <div className="book__section__two__ratings__start">
                    <div>
                      <h3>Writing quality</h3>
                      <span>
                        {Array(Math.round(average))
                          .fill()
                          .map(() => {
                            return <BsStarFill />;
                          })}
                      </span>
                    </div>
                    <div>
                      <h3>Writing quality</h3>
                      <span>
                        {Array(Math.round(average))
                          .fill()
                          .map(() => {
                            return <BsStarFill />;
                          })}
                      </span>
                    </div>
                    <div>
                      <h3>Stability of updates</h3>
                      <span>
                        {Array(Math.round(average))
                          .fill()
                          .map(() => {
                            return <BsStarFill />;
                          })}
                      </span>
                    </div>
                    <div>
                      <h3>Story Development</h3>
                      <span>
                        {Array(Math.round(average))
                          .fill()
                          .map(() => {
                            return <BsStarFill />;
                          })}
                      </span>
                    </div>
                    <div>
                      <h3>Character Design</h3>
                      <span>
                        {Array(Math.round(average))
                          .fill()
                          .map(() => {
                            return <BsStarFill />;
                          })}
                      </span>
                    </div>
                    <div>
                      <h3>World background</h3>
                      <span>
                        {Array(Math.round(average))
                          .fill()
                          .map(() => {
                            return <BsStarFill />;
                          })}
                      </span>
                    </div>
                  </div>
                  <div className="book__section__two__ratings__end">
                    <span>
                      {Array(Math.round(average))
                        .fill()
                        .map(() => {
                          return <BsStarFill />;
                        })}
                    </span>
                    <p>
                      <b>8.0</b>(9012 Reviews)
                    </p>
                    <button
                      className="book__section__two__ratings__end__bt"
                      onClick={() => handleModalClick("review")}
                    >
                      DROP A REVIEW
                    </button>
                  </div>
                </div>
                <div className="book__section__two__recommendations">
                  <h1>You may also like</h1>
                  <span className="book__section__two__recommendations__span">
                    <div>
                      <img alt="" src={image4} />
                      <h1>Almost Perfect Parners</h1>
                      <span>
                        <h3>Progress</h3>
                        <h4>1/2380</h4>
                      </span>
                    </div>
                    <div>
                      <img alt="" src={image4} />
                      <h1>Almost Perfect Parners</h1>
                      <span>
                        <h3>Progress</h3>
                        <h4>1/2380</h4>
                      </span>
                    </div>
                    <div>
                      <img alt="" src={image4} />
                      <h1>Almost Perfect Parners</h1>
                      <span>
                        <h3>Progress</h3>
                        <h4>1/2380</h4>
                      </span>
                    </div>
                    <div>
                      <img alt="" src={image4} />
                      <h1>Almost Perfect Parners</h1>
                      <span>
                        <h3>Progress</h3>
                        <h4>1/2380</h4>
                      </span>
                    </div>
                    <div>
                      <img alt="" src={image4} />
                      <h1>Almost Perfect Parners</h1>
                      <span>
                        <h3>Progress</h3>
                        <h4>1/2380</h4>
                      </span>
                    </div>
                    <div>
                      <img alt="" src={image4} />
                      <h1>Almost Perfect Parners</h1>
                      <span>
                        <h3>Progress</h3>
                        <h4>1/2380</h4>
                      </span>
                    </div>
                    <div>
                      <img alt="" src={image4} />
                      <h1>Almost Perfect Parners</h1>
                      <span>
                        <h3>Progress</h3>
                        <h4>1/2380</h4>
                      </span>
                    </div>
                  </span>
                </div>
                <div className="book__section__two__reviews">
                  <h1>Reviews</h1>
                  <div>
                    <h1>FAHRENHEIT_RM</h1>
                    <p>
                      I've been reading this book for a while now, and it's
                      amazing! although my words can't do this work of art
                      justice I'll try my best: The characters feel so real and
                      the author does an amazing job in making all of the
                      magical laws make sense. when the protagonist becomes more
                      powerful it isn't in some vague way that he is suddenly
                      "stronger" but he gains some new thing through hard work.
                      it makes the story that much more impressive because it
                      feels like he is just describing how another world works
                      rather than some make belive thing!
                    </p>
                    <span>
                      <h3>12-09-2023 10:00AM</h3>
                      <span>
                        <FaThumbsUp className="book__section__two__reviews__thumbsup" />
                        <p>23</p>
                        <FaThumbsDown className="book__section__two__reviews__thumbsdown" />
                        <p>23</p>
                      </span>
                    </span>
                  </div>
                  <div>
                    <h1>FAHRENHEIT_RM</h1>
                    <p>
                      I've been reading this book for a while now, and it's
                      amazing! although my words can't do this work of art
                      justice I'll try my best: The characters feel so real and
                      the author does an amazing job in making all of the
                      magical laws make sense. when the protagonist becomes more
                      powerful it isn't in some vague way that he is suddenly
                      "stronger" but he gains some new thing through hard work.
                      it makes the story that much more impressive because it
                      feels like he is just describing how another world works
                      rather than some make belive thing!
                    </p>
                    <span>
                      <h3>12-09-2023 10:00AM</h3>
                      <span>
                        <FaThumbsUp className="book__section__two__reviews__thumbsup" />
                        <p>23</p>
                        <FaThumbsDown className="book__section__two__reviews__thumbsdown" />
                        <p>23</p>
                      </span>
                    </span>
                  </div>
                  <div>
                    <h1>FAHRENHEIT_RM</h1>
                    <p>
                      I've been reading this book for a while now, and it's
                      amazing! although my words can't do this work of art
                      justice I'll try my best: The characters feel so real and
                      the author does an amazing job in making all of the
                      magical laws make sense. when the protagonist becomes more
                      powerful it isn't in some vague way that he is suddenly
                      "stronger" but he gains some new thing through hard work.
                      it makes the story that much more impressive because it
                      feels like he is just describing how another world works
                      rather than some make belive thing!
                    </p>
                    <span>
                      <h3>12-09-2023 10:00AM</h3>
                      <span>
                        <FaThumbsUp className="book__section__two__reviews__thumbsup" />
                        <p>23</p>
                        <FaThumbsDown className="book__section__two__reviews__thumbsdown" />
                        <p>23</p>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            )}
            {select.select2 && (
              <div>
                <div className="book__section__two__catalog">
                  <h1>1,125 Chapters</h1>
                </div>
                <div className="book__section__two__catalog__chapter">
                  <h1>Chapter 1</h1>
                  <h2>The part of the reason</h2>
                  <p>
                    After recent divorcée Julie has sworn off romance to focus
                    on her marketing career, her friends finally convince her to
                    cautiously dip a toe back in by trying online dating. To
                    Julie's surprise, she seems to hit it off with Blake, a
                    charming architect. But over time, red flags appear
                    revealing that Blake is still secretly obsessed with and
                    harboring hopes to reunite with his ex, Lila.{" "}
                  </p>
                  <h3>READ</h3>
                </div>
                <div className="book__section__two__catalog__chapter">
                  <h1>Chapter 1</h1>
                  <h2>The part of the reason</h2>
                  <p>
                    After recent divorcée Julie has sworn off romance to focus
                    on her marketing career, her friends finally convince her to
                    cautiously dip a toe back in by trying online dating. To
                    Julie's surprise, she seems to hit it off with Blake, a
                    charming architect. But over time, red flags appear
                    revealing that Blake is still secretly obsessed with and
                    harboring hopes to reunite with his ex, Lila.{" "}
                  </p>
                  <h3>READ</h3>
                </div>
                <div className="book__section__two__catalog__chapter">
                  <h1>Chapter 1</h1>
                  <h2>The part of the reason</h2>
                  <p>
                    After recent divorcée Julie has sworn off romance to focus
                    on her marketing career, her friends finally convince her to
                    cautiously dip a toe back in by trying online dating. To
                    Julie's surprise, she seems to hit it off with Blake, a
                    charming architect. But over time, red flags appear
                    revealing that Blake is still secretly obsessed with and
                    harboring hopes to reunite with his ex, Lila.{" "}
                  </p>
                  <h3>READ</h3>
                </div>
              </div>
            )}
          </>
        </div>
      </div>
      <Footer />

      <Modal isOpen={isOpen.review} onClose={closeModal}>
        <div className="book__modal__review">
          <h1> Write a review</h1>
          <span>
            <div className="book__modal__review__rating__start">
              <div>
                <h3>Writing quality</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>Writing quality</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>Stability of updates</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>Story Development</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>Character Design</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
              <div>
                <h3>World background</h3>
                <span>
                  {Array(Math.round(average))
                    .fill()
                    .map(() => {
                      return <BsStarFill />;
                    })}
                </span>
              </div>
            </div>
            <div className="book__modal__review__rating__end">
              <h3>Total score</h3>
              <h1>0.0</h1>
              <span>
                {Array(Math.round(average))
                  .fill()
                  .map(() => {
                    return <BsStarFill />;
                  })}
              </span>
            </div>
          </span>
          <textarea>
            Type your review here. Your review should be important to the story
            and as detailed as you can. (At least 140 characters)
          </textarea>
          <div className="book__modal__review__cta">
            <span>
              <button>Post</button>
            </span>
          </div>
        </div>
      </Modal>
      <Modal isOpen={isOpen.gift} onClose={closeModal}>
        <div className="book__modal__gift">
          <div className="book__modal__gift__start">
            <div>
              <img alt="item" src={gift1} />
              <span>
                <p>10</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift2} />
              <span>
                <p>50</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift3} />
              <span>
                <p>100</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift4} />
              <span>
                <p>500</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift5} />
              <span>
                <p>1000</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift6} />
              <span>
                <p>2000</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift7} />
              <span>
                <p>5000</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
            <div>
              <img alt="item" src={gift8} />
              <span>
                <p>10000</p>
                <img src={coin} alt="coin" />
              </span>
            </div>
          </div>
          <div className="book__modal__gift__end">
            <button className="book__section__two__ratings__end__bt">
              SEND GIFT
            </button>
            <span>
              <p>Balance: 0 </p>
              <img src={coin} alt="coin" />
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Book;
