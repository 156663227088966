import api from "../api/axios";

export const login = async (email, password) => {
  try {
    const response = await api.post("/token/login", { email, password });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const logout = async () => {
  try {
    const response = await api.post("/token/logout");
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const signup = async (userData) => {
  try {
    const response = await api.post("/credential/users/", userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};
