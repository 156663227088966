import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteUser,
  fetchUserProfile,
  updateUserProfile,
} from "../../services/userServices";

import { useDispatcher } from "../../utils/useDispatcher";

export const loadUserProfile = createAsyncThunk(
  "user/loadUserProfile",
  async (userId, { rejectWithValue }) => {
    try {
      const data = await fetchUserProfile(userId);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to load user profile");
    }
  }
);

export const updateUser = createAsyncThunk(
  "user/updateUser",
  async ({ userId, userData }, { rejectWithValue }) => {
    try {
      const data = await updateUserProfile(userId, userData);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to update user profile");
    }
  }
);

export const deleteUserAccount = createAsyncThunk(
  "user/deleteUserAccount",
  async (userId, { rejectWithValue }) => {
    try {
      await deleteUser(userId);
    } catch (error) {
      return rejectWithValue(error.message || "Failed to delete user account");
    }
  }
);

export const useLoadUserProfile = () => useDispatcher(loadUserProfile);
export const useUpdateUser = () => useDispatcher(updateUser);
export const useDeleteUserAccount = () => useDispatcher(deleteUserAccount);
