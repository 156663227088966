import { BiUpload } from "react-icons/bi";
import { useState } from "react";

import Header from "../../components/layout/header/Header";
import "./create-book.css";
import logo from "../../../assets/icons/logo2.png";
import Footer from "../../components/layout/footer/Footer";

function CreateBook() {
  const [formData, setFormData] = useState({
    bookTitle: "",
    language: "",
    leadingGender: "",
    genre: "",
    author: "",
    releaseDate: "",
    description: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target.value;
    setFormData({ ...formData, [name]: value });
  };
  return (
    <div className="create__bg">
      <div className="create">
        <Header />
        <section className="create__book">
          <form>
            <div className="create__book__upload">
              <div>
                <img src={logo} alt="BonReader logo" />
                <input
                  type="file"
                  id="bookCover"
                  name="bookCover"
                  accept=".jpg,.png"
                  required
                />
              </div>

              <button>
                <BiUpload /> Upload
              </button>
            </div>
            <span className="create__book__form__input__block entire--span">
              <label for="bookTitle">Book Title</label>
              <input
                name="bookTitle"
                value={formData.bookTitle}
                type="text"
                id="bookTitle"
                required
                onChange={handleChange}
              />
            </span>
            <span className="create__book__form__input__block">
              <label for="language">Language</label>
              <select
                required
                name="language"
                id="language"
                value={formData.updateRate}
                onChange={handleChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                <option value="Option one">Option one</option>
                <option value="Option two">Option two</option>
                <option value="Option three">Option three</option>
                <option value="Option four">Option four</option>
              </select>
            </span>
            <span className="create__book__form__input__block">
              <label for="leadingGender">Leading Gender</label>
              <select
                required
                name="leadingGender"
                id="leadingGender"
                value={formData.leadingGender}
                onChange={handleChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                <option value="Option one">Option one</option>
                <option value="Option two">Option two</option>
                <option value="Option three">Option three</option>
                <option value="Option four">Option four</option>
              </select>
            </span>{" "}
            <span className="create__book__form__input__block">
              <label for="genre">Genre</label>
              <select
                required
                name="genre"
                id="genre"
                value={formData.genre}
                onChange={handleChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                <option value="Option one">Option one</option>
                <option value="Option two">Option two</option>
                <option value="Option three">Option three</option>
                <option value="Option four">Option four</option>
              </select>
            </span>{" "}
            <span className="create__book__form__input__block">
              <label for="length">Length</label>
              <select
                required
                name="length"
                id="length"
                value={formData.length}
                onChange={handleChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                <option value="Option one">Option one</option>
                <option value="Option two">Option two</option>
                <option value="Option three">Option three</option>
                <option value="Option four">Option four</option>
              </select>
            </span>{" "}
            <span className="create__book__form__input__block">
              <label for="rating">Rating</label>
              <select
                required
                name="rating"
                id="rating"
                value={formData.updateRate}
                onChange={handleChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                <option value="Option one">Option one</option>
                <option value="Option two">Option two</option>
                <option value="Option three">Option three</option>
                <option value="Option four">Option four</option>
              </select>
            </span>{" "}
            <span className="create__book__form__input__block">
              <label for="tags">Tags</label>
              <select
                required
                name="tags"
                id="tags"
                value={formData.updateRate}
                onChange={handleChange}
                className="modal__apply__input"
              >
                <option value={null}>--</option>
                <option value="Option one">Option one</option>
                <option value="Option two">Option two</option>
                <option value="Option three">Option three</option>
                <option value="Option four">Option four</option>
              </select>
            </span>
            <span className="create__book__form__input__block ">
              <label for="synopsis">Synopsis</label>
              <textarea
                required
                name="synopsis"
                id="synopsis"
                value={formData.synopsis}
                onChange={handleChange}
                className="create__book__form__input__block__textarea"
              ></textarea>
            </span>
            <button>Create</button>
          </form>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default CreateBook;
