// features/wallet/walletSlice.js
import { createSlice } from "@reduxjs/toolkit";
import {
  addWalletFunds,
  loadWalletBalance,
  withdrawWalletFunds,
} from "../actions/walletActions";

// Define initial state
const initialState = {
  balance: 0,
  loading: false,
  error: null,
};

// Create the slice
const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadWalletBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(loadWalletBalance.fulfilled, (state, action) => {
        state.balance = action.payload.balance;
        state.loading = false;
      })
      .addCase(loadWalletBalance.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(addWalletFunds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addWalletFunds.fulfilled, (state, action) => {
        state.balance += action.payload.balance;
        state.loading = false;
      })
      .addCase(addWalletFunds.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(withdrawWalletFunds.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(withdrawWalletFunds.fulfilled, (state, action) => {
        state.balance -= action.payload.balance;
        state.loading = false;
      })
      .addCase(withdrawWalletFunds.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default walletSlice.reducer;
