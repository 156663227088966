import Header from "../../components/layout/header/Header";
import image1 from "../../../assets/images/glasses-book-create.png";
import "./create.css";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/layout/footer/Footer";

function Create() {
  const navigate = useNavigate();

  return (
    <div className="create__bg">
      <div className="create">
        <Header />
        <section className="create__section__one">
          <img
            src={image1}
            alt="reading glasses on book"
            className="create__section__one__image"
          />
          <button
            className="create__section__one__button"
            onClick={() => navigate("/create-book")}
          >
            Create your first book now!
          </button>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Create;
