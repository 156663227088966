import "./Ranking.css";
import Footer from "../../components/layout/footer/Footer";
import Header from "../../components/layout/header/Header";
import { NOVEL } from "../../../config/data";
import { useNavigate } from "react-router-dom";

function Ranking() {
  const navigate = useNavigate();

  return (
    <div className="ranking">
      <div className="ranking__section">
        <Header />
        <section className="ranking__section__one">
          <div className="ranking__section__one__nav">
            <h1>Ranking</h1>
            <h3 className="ranking__section__one__nav__active">All</h3>
            <h3>Power</h3>
            <h3>Trending</h3>
            <h3>Popular</h3>
            <h3>Active</h3>
          </div>
          <div className="ranking__section__one__body">
            <div className="ranking__section__one__body__display">
              {NOVEL.map(function (item, i) {
                // Check if the index is less than six
                if (i < 10) {
                  return (
                    <div className="ranking__section__one__body__display__block">
                      <h1>{i + 1}</h1>
                      <img alt="" src={item.image} />
                      <div className="ranking__section__one__display__block__about">
                        <h1>{item.title}</h1>
                        <span>
                          <h1>{item.author}</h1> <h2>{item.read}</h2>
                        </span>
                        <p>{item.description}</p>
                        <span>
                          <h1> Romance</h1>
                          <h1>Crime</h1>
                        </span>
                      </div>
                      <article>
                        <button>Add</button>
                        <button onClick={() => navigate("/book")}>Read</button>
                      </article>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Ranking;
