import { useNavigate } from "react-router-dom";

import Footer from "../../components/layout/footer/Footer";
import Header from "../../components/layout/header/Header";
import { NOVEL } from "../../../config/data";
import "./Genre.css";

function Genre() {
  //Hook declarations
  const navigate = useNavigate();

  return (
    <div className="genre">
      <div className="genre__section">
        <Header />
        <section className="genre__section__one">
          <div className="genre__section__one__nav">
            <h1>Genre</h1>
            <h3 className="genre__section__one__nav__active">All</h3>
            <h3>Romance</h3>
            <h3>Crime</h3>
            <h3>Mystery</h3>
            <h3>Vampire</h3>
            <h3>LGBTQ+</h3>
            <h3>Fiction</h3>
            <h3>Comedy</h3>
            <h3>Horror</h3>
            <h3>Fantasy</h3>
            <h3>Werewolf</h3>
            <h3>Romance</h3>
          </div>
          <div className="genre__section__one__body">
            <div className="genre__section__one__body__div1">
              <h1>Filter By</h1>
              <span>
                <h1>All</h1>
                <h1>Ongoing</h1>
                <h1>Completed</h1>
              </span>
            </div>
            <div className="genre__section__one__body__div1">
              <h1>Filter By</h1>
              <span>
                <h1>All</h1>
                <h1>Ongoing</h1>
                <h1>Completed</h1>
              </span>
            </div>
            <div className="genre__section__one__body__display">
              {NOVEL.map(function (item, i) {
                // Check if the index is less than six
                if (i < 6) {
                  return (
                    <div
                      onClick={() => navigate("/book")}
                      className="genre__section__one__body__display__block"
                    >
                      <img alt="" src={item.image} />
                      <div className="genre__section__one__display__block__about">
                        <h1>{item.title}</h1>
                        <span>
                          <h1>{item.author}</h1> <h2>{item.read}</h2>
                        </span>
                        <p>{item.description}</p>
                        <span>
                          <h1> Romance</h1>
                          <h1>Crime</h1>
                        </span>
                      </div>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Genre;
