import React, { useRef, useState, useEffect } from "react";
import axios from "axios";
import "./text-editor.css";

const TextEditor = () => {
  const editorRef = useRef(null);
  const [wordCount, setWordCount] = useState(0);
  const [showPreview, setShowPreview] = useState(false);
  const [previewContent, setPreviewContent] = useState("");
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);

  useEffect(() => {
    const handleInput = () => {
      if (editorRef.current) {
        const textContent = editorRef.current.innerText;
        const wordArray = textContent.trim().split(/\s+/);
        setWordCount(wordArray.filter((word) => word.length > 0).length);
      }
    };
    editorRef.current.addEventListener("input", handleInput);
    // return () => editorRef.current.removeEventListener("input", handleInput);
  }, []);

  useEffect(() => {
    const handleSelectionChange = () => {
      setIsBold(document.queryCommandState("bold"));
      setIsItalic(document.queryCommandState("italic"));
      setIsUnderline(document.queryCommandState("underline"));
    };

    document.addEventListener("selectionchange", handleSelectionChange);
    return () =>
      document.removeEventListener("selectionchange", handleSelectionChange);
  }, []);

  const formatText = (command) => {
    document.execCommand(command, false, null);
    setIsBold(document.queryCommandState("bold"));
    setIsItalic(document.queryCommandState("italic"));
    setIsUnderline(document.queryCommandState("underline"));
  };

  const handleSave = () => {
    return;
  };

  const exportText = async () => {
    if (editorRef.current) {
      const textContent = editorRef.current.innerHTML;
      console.log(textContent);
      try {
        const response = await axios.post(
          "http://your-backend-url.com/api/save",
          {
            content: textContent,
          }
        );
        console.log("Response:", response.data);
      } catch (error) {
        console.error("Error exporting text:", error);
      }
    }
  };

  const handlePreview = () => {
    if (editorRef.current) {
      setPreviewContent(editorRef.current.innerHTML);
      setShowPreview(true);
    }
  };

  const closePreview = () => {
    setShowPreview(false);
  };

  return (
    <div className="text-editor">
      <div className="toolbar">
        <button
          className={isBold ? "active" : ""}
          onClick={() => formatText("bold")}
        >
          <b>B</b>
        </button>
        <button
          className={isItalic ? "active" : ""}
          onClick={() => formatText("italic")}
        >
          <i>I</i>
        </button>
        <button
          className={isUnderline ? "active" : ""}
          onClick={() => formatText("underline")}
        >
          <u>U</u>
        </button>
        <button onClick={() => formatText("undo")}>Undo</button>
        <button onClick={() => formatText("redo")}>Redo</button>
      </div>

      <div
        ref={editorRef}
        className="editor"
        contentEditable="true"
        suppressContentEditableWarning={true}
      ></div>

      <div className="text__editor__cta">
        <span>
          <button className="save-button" onClick={handleSave}>
            Save
          </button>
          <button className="preview-button" onClick={handlePreview}>
            Preview
          </button>
        </span>
        <span className="text__editor__cta__end">
          <div className="word-count">Word Count: {wordCount}</div>
          <button className="export-button" onClick={exportText}>
            Publish
          </button>
        </span>
      </div>

      {showPreview && (
        <div className="preview-modal">
          <div className="preview-content">
            <div className="modal__content__span">
              <button className="close-button" onClick={closePreview}>
                Close
              </button>
            </div>
            <div dangerouslySetInnerHTML={{ __html: previewContent }}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextEditor;
