import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.css";
import Login from "./common-pages/login/Login";
import Signup from "./common-pages/signup/Signup";
import ScrollToTop from "./utils/ScrollToTop";
import Home from "./user/pages/home/Home";
import Genre from "./user/pages/genre/Genre";
import Ranking from "./user/pages/ranking/Ranking";
import Shelf from "./user/pages/shelf/Shelf";
import Book from "./user/pages/book/Book";
import Account from "./user/pages/account/Account";
import AccountDashboard from "./user/pages/account.dashboard/Account.dashboard";
import AccountStories from "./user/pages/account.stories/Account.stories";
import AccountInbox from "./user/pages/account.inbox/AccountInbox";
import AccountTransactionHistory from "./user/pages/account.transaction.history/AccountTransactionHistory";
import AccountIncome from "./user/pages/acoount.income/Account.income";
import BookPage from "./user/pages/book-page/BookPage";
import { ADMIN_ROLES } from "./config/adminRoles";
import PublicRoute from "./route/publicRoute";
import PrivateRoute from "./route/privateRoute";
import Admin from "./admin/pages/admin/Admin";
import AdminDashboard from "./admin/pages/admin-dashboard/AdminDashboard";
import AdminNovel from "./admin/pages/admin-novels/AdminNovel";
import AdminGenre from "./admin/pages/admin-genre/AdminGenre";
import AdminUsers from "./admin/pages/users/AdminUsers";
import AdminAuthors from "./admin/pages/authors/AdminAuthors";
import AdminSubscriptions from "./admin/pages/admin-subscriptions/AdminSunscriptions";
import AdminCoins from "./admin/pages/admin-coins/AdminCoins";
import AdminWithdrawals from "./admin/pages/admin-withdrawals/admin-withdrawals";
import AdminAdmins from "./admin/pages/admin-admins/AdminAdmins";
import AdminRoles from "./admin/pages/admin-roles/AdminRoles";
import AdminTags from "./admin/pages/admin-tags/AdminTag";
import AdminContracts from "./admin/pages/admin-contracts/AdminContracts";
import AdminSettings from "./admin/pages/admin-settings/AdminSettings";
import AdminEditorial from "./admin/pages/admin-editorial/AdminEditorial";
import AdminReview from "./admin/pages/admin-review/AdminReview";
import Contract from "./user/pages/contract/Contract";
import CreateNovel from "./user/pages/create-novel/CreateNovel";
import NewChapter from "./user/pages/new-chapter/NewChapter";
import Create from "./user/pages/create/Create";
import CreateBook from "./user/pages/create-book/CreateBook";
import AdminRolesSingle from "./admin/pages/admin-roles-single/AdminRolesSingle";
import AdminNewAdmin from "./admin/pages/admin-new-admin/AdminNewAdmin";
import AdminProfile from "./admin/pages/admin-profile/AdminProfile";
import AdminEditProfile from "./admin/pages/admin-profile-edit/AdminProfileEdit";
import AdminAuthorsSingle from "./admin/pages/admin-authors-single/AdminAuthorSingle";
import AdminSubscriptionsPlan from "./admin/pages/admin-subscriptions-plan/AdminSubscriptionPlan";
import AdminCoinsNew from "./admin/pages/admin-coins-new/AdminCoinsNew";
import AdminUserSingle from "./admin/pages/admin-user-single/AdminUserSingle";
import AdminNovelsSingle from "./admin/pages/admin-novels-single/AdminNovelSingle";
import AdminBookChapter from "./admin/pages/admin-book-chapter/AdminBookChapter";
import AdminNovelsRejected from "./admin/pages/admin-novels-rejected/AdminNovelsRejected";
import AdminNovelsApproved from "./admin/pages/admin-novels-approved/AdminNovelsApproved";
import AdminNovelsNew from "./admin/pages/admin-novels-new/AdminNovelsNew";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          {/* PUBLIC ROUTES */}
          <Route path="/" element={<PublicRoute />}>
            <Route index element={<Home />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/genre" element={<Genre />} />
            <Route path="/ranking" element={<Ranking />} />
            <Route path="/shelf" element={<Shelf />} />
            <Route path="/book" element={<Book />} />
            <Route path="/book-page" element={<BookPage />} />
            <Route path="new-chapter" element={<NewChapter />} />
            <Route path="create" element={<Create />} />
            <Route path="create-book" element={<CreateBook />} />
            <Route path="new-chapter" element={<NewChapter />} />

            <Route element={<Account />}>
              <Route path="/account-dashboard" element={<AccountDashboard />} />
              <Route path="/account-stories" element={<AccountStories />} />
              <Route path="/account-inbox" element={<AccountInbox />} />
              <Route path="/account-income" element={<AccountIncome />} />
              <Route path="/contract" element={<Contract />} />
              <Route path="/create-novel" element={<CreateNovel />} />
              <Route
                path="/account-transaction-history"
                element={<AccountTransactionHistory />}
              />
            </Route>

            {/* PRIVATE ROUTES */}
            <Route element={<PrivateRoute allowedRoles={ADMIN_ROLES} />}>
              <Route path="/admin" element={<Admin />}>
                <Route path="dashboard" element={<AdminDashboard />} />
                <Route path="novels" element={<AdminNovel />} />
                <Route
                  path="novels-rejected"
                  element={<AdminNovelsRejected />}
                />
                <Route
                  path="novels-approved"
                  element={<AdminNovelsApproved />}
                />
                <Route path="novels-new" element={<AdminNovelsNew />} />
                <Route path="novels/:id" element={<AdminNovelsSingle />} />
                <Route
                  path="novels/:id/chapter/:chapterId"
                  element={<AdminBookChapter />}
                />
                <Route path="genre" element={<AdminGenre />} />
                <Route path="users" element={<AdminUsers />} />
                <Route path="users/:id" element={<AdminUserSingle />} />
                <Route path="authors" element={<AdminAuthors />} />
                <Route path="authors/:id" element={<AdminAuthorsSingle />} />
                <Route path="subscriptions" element={<AdminSubscriptions />} />
                <Route
                  path="subscriptions/plan"
                  element={<AdminSubscriptionsPlan />}
                />
                <Route path="coins" element={<AdminCoins />} />
                <Route path="coins/new" element={<AdminCoinsNew />} />
                <Route path="withdrawals" element={<AdminWithdrawals />} />
                <Route path="admins" element={<AdminAdmins />} />
                <Route path="admins/create" element={<AdminNewAdmin />} />
                <Route path="withdrawals" element={<AdminWithdrawals />} />
                <Route path="roles" element={<AdminRoles />} />
                <Route path="admin-roles" element={<AdminRolesSingle />} />
                <Route path="tags" element={<AdminTags />} />
                <Route path="contracts" element={<AdminContracts />} />
                <Route path="settings" element={<AdminSettings />} />
                <Route path="review" element={<AdminReview />} />
                <Route path="editorial-picks" element={<AdminEditorial />} />
                <Route path="profile" element={<AdminProfile />} />
                <Route path="profile/edit" element={<AdminEditProfile />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
