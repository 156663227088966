import { createAsyncThunk } from "@reduxjs/toolkit";

import { useDispatcher } from "../../utils/useDispatcher";
import {
  addBook,
  deleteBook,
  fetchBook,
  fetchBooks,
  updateBook,
} from "../../services/bookServices";

// Load all books
export const loadBooks = createAsyncThunk(
  "books/loadBooks",
  async (_, { rejectWithValue }) => {
    try {
      const data = await fetchBooks();
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to load books");
    }
  }
);

// Load a single book
export const loadBook = createAsyncThunk(
  "books/loadBook",
  async (bookId, { rejectWithValue }) => {
    try {
      const data = await fetchBook(bookId);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to load book");
    }
  }
);

// Add a new book
export const createBook = createAsyncThunk(
  "books/createBook",
  async (bookData, { rejectWithValue }) => {
    try {
      const data = await addBook(bookData);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to add book");
    }
  }
);

// Update an existing book
export const editBook = createAsyncThunk(
  "books/editBook",
  async ({ bookId, bookData }, { rejectWithValue }) => {
    try {
      const data = await updateBook(bookId, bookData);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to update book");
    }
  }
);

// Delete a book
export const removeBook = createAsyncThunk(
  "books/removeBook",
  async (bookId, { rejectWithValue }) => {
    try {
      const data = await deleteBook(bookId);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to delete book");
    }
  }
);

export const useLoadBooks = () => useDispatcher(loadBooks);
export const useLoadBook = () => useDispatcher(loadBook);
export const useCreateBook = () => useDispatcher(createBook);
export const useEditBook = () => useDispatcher(editBook);
export const useRemoveBook = () => useDispatcher(removeBook);
