import { combineReducers } from "redux";
import authReducer from "./authSlice.js";
import bookReducer from "./bookSlice.js";
import userReducer from "./userSlice.js";
import walletReducer from "./walletSlice.js";

export default combineReducers({
  auth: authReducer,
  book: bookReducer,
  user: userReducer,
  wallet: walletReducer,
});
