import { createSlice } from "@reduxjs/toolkit";
import {
  loadBooks,
  loadBook,
  createBook,
  editBook,
  removeBook,
} from "../actions/bookActions";

const initialState = {
  list: [],
  selectedBook: null,
  loading: false,
  error: null,
};

const bookSlice = createSlice({
  name: "books",
  initialState,
  reducers: {
    resetBookState: (state) => {
      state.books = [];
      state.bookDetails = null;
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Load all books
      .addCase(loadBooks.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadBooks.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(loadBooks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Load a single book
      .addCase(loadBook.pending, (state) => {
        state.loading = true;
      })
      .addCase(loadBook.fulfilled, (state, action) => {
        state.loading = false;
        state.selectedBook = action.payload;
      })
      .addCase(loadBook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Add a new book
      .addCase(createBook.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBook.fulfilled, (state, action) => {
        state.loading = false;
        state.list.push(action.payload);
      })
      .addCase(createBook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update an existing book
      .addCase(editBook.pending, (state) => {
        state.loading = true;
      })
      .addCase(editBook.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.list.findIndex(
          (book) => book.id === action.payload.id
        );
        if (index >= 0) {
          state.list[index] = action.payload;
        }
      })
      .addCase(editBook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Delete a book
      .addCase(removeBook.pending, (state) => {
        state.loading = true;
      })
      .addCase(removeBook.fulfilled, (state, action) => {
        state.loading = false;
        state.list = state.list.filter((book) => book.id !== action.payload.id);
      })
      .addCase(removeBook.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetBookState } = bookSlice.actions;
export default bookSlice.reducer;
