import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  LOADING,
} from "../types/authTypes";
import { login, logout, signup } from "../../services/authServices";
import { delay } from "../../utils/delay";
import { useDispatcher } from "../../utils/useDispatcher";

// Action Creators
const loginSuccess = (user) => ({
  type: LOGIN_SUCCESS,
  payload: user,
});

const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

const loggingIn = () => ({
  type: LOADING,
});

const logoutAction = () => ({
  type: LOGOUT,
});

// Custom Hook: Use Auth
export function useAuth() {
  const auth = useSelector((state) => state.auth);
  return auth;
}

// Custom Hook: Initialize Auth
export function useInitAuth() {
  const dispatch = useDispatch();

  useEffect(() => {
    const initAuth = async () => {
      await delay(2000);
      dispatch(logoutAction());
    };

    initAuth();
  }, [dispatch]);
}

// Async Actions
export const doLoginAction = (email, password) => async (dispatch) => {
  dispatch(loggingIn());

  try {
    const response = await login(email, password);
    dispatch(loginSuccess({ email, name: response.name }));
  } catch (error) {
    dispatch(loginFailure(error.message || "Login failed"));
  }
};

export const doSignUpAction =
  (email, password, firstName, lastName) => async (dispatch) => {
    dispatch(loggingIn());

    try {
      const response = await signup(email, password, firstName, lastName);
      dispatch(loginSuccess({ email, name: `${firstName} ${lastName}` }));
    } catch (error) {
      dispatch(loginFailure(error.message || "Signup failed"));
    }
  };

export const doLogoutAction = () => async (dispatch) => {
  dispatch(loggingIn());

  try {
    const response = await logout();
    // await deleteAllTokens();
    dispatch(logoutAction());
  } catch (error) {
    console.error("Logout failed:", error);
    dispatch(loginFailure(error.message || "Logout failed"));
  }
};

// Custom Hooks for Dispatching Actions
export const useLogin = () => useDispatcher(doLoginAction);
export const useLogout = () => useDispatcher(doLogoutAction);
export const useSignUp = () => useDispatcher(doSignUpAction);
