import { useState } from "react";
import Meta from "../../../utils/Meta";
import "./contract.css";

function Contract() {
  const [formData, setFormData] = useState({
    email: "",
    fullName: "",
    penName: "",
    facebookHandle: "",
    documentType: "",
    documentNumber: "",
    country: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target.value;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // submit form data to server
    console.log(formData);
  };

  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });

  const handleNext = () => {
    setSelect((prevState) => ({
      ...prevState,
      select1: false,
      select2: true,
    }));
  };
  const handleBack = () => {
    setSelect((prevState) => ({
      ...prevState,
      select1: true,
      select2: false,
    }));
  };

  return (
    <div className="contract">
      <Meta title="contract -BONREADER" />
      <section className="contract__section__one">
        <h1>Bonreader Contract Application</h1>
        <p>
          1. We collect the information you submit in this form for contract use
          only. We will not share your information with third parties. We need
          your personal information to verify the legal validity of our
          contract. by submitting this form, you certify that the provided
          information is real and correct to thr best of your knowledge.
        </p>
        <p>
          2. To ensure account security, we require each account to have unique
          personal information, so please so not sign up for a ne account to
          apply for a contract.
        </p>
      </section>
      <section className="contract__section__two">
        {select.select1 && (
          <form>
            <h1>Personal Information:</h1>

            <div className="contract__section__two__form__two">
              <span className="contract__section__two__form__input__block">
                <label for="email">Email address</label>
                <input
                  name="email"
                  type="email"
                  value={formData.email}
                  id="email"
                  required
                  onChange={handleChange}
                />
              </span>
              <span className="contract__section__two__form__input__block">
                <label for="email">Full Name</label>
                <input
                  name="fullName"
                  type="text"
                  value={formData.fullName}
                  id="fullName"
                  required
                  onChange={handleChange}
                />
              </span>
              <span className="contract__section__two__form__input__block">
                <label for="email"> Pen Name</label>
                <input
                  name="penName"
                  type="text"
                  value={formData.penName}
                  id="penName"
                  required
                  onChange={handleChange}
                />
              </span>
              <span className="contract__section__two__form__input__block">
                <label for="facebookHandle">Facebook Handle</label>
                <input
                  name="facebookHandle"
                  type="facebookHandle"
                  value={formData.facebookHandle}
                  id="facebookHandle"
                  required
                  onChange={handleChange}
                />
              </span>
              <span className="contract__section__two__form__input__block">
                <label for="number">Document Number</label>
                <input
                  name="number"
                  type="number"
                  value={formData.documentNumber}
                  id="number"
                  required
                  onChange={handleChange}
                />
              </span>
              <span className="contract__section__two__form__input__block">
                <label for="number">Phone Number</label>
                <input
                  name="number"
                  type="number"
                  value={formData.number}
                  id="number"
                  required
                  onChange={handleChange}
                />
              </span>
              <span className="contract__section__two__form__input__block">
                <label for="documentType">Document Type</label>
                <select
                  required
                  name="documentType"
                  id="documentType"
                  value={formData.documentType}
                  onChange={handleChange}
                  className="modal__apply__input"
                >
                  <option value={null}>--</option>
                  <option value="Option one">Option one</option>
                  <option value="Option two">Option two</option>
                  <option value="Option three">Option three</option>

                  <option value="Option four">Option four</option>
                </select>
              </span>
              <span className="contract__section__two__form__input__block">
                <label for="number">Document Number</label>
                <input
                  name="number"
                  type="number"
                  value={formData.documentNumber}
                  id="number"
                  required
                  onChange={handleChange}
                />
              </span>
            </div>
            <div className="contract__section__two__form__two">
              <span className="contract__section__two__form__input__block entire--span">
                <label for="address">Permanent Address</label>
                <input
                  name="address"
                  value={formData.personalAddress}
                  type="text"
                  id="address"
                  required
                  onChange={handleChange}
                />
              </span>
            </div>
            <div className="contract__section__two__form__two">
              <span className="contract__section__two__form__input__block one--third">
                <label for="address">City</label>
                <input
                  name="city"
                  value={formData.city}
                  type="text"
                  id="city"
                  required
                  onChange={handleChange}
                />
              </span>
              <span className="contract__section__two__form__input__block one--third">
                <label for="state">State</label>
                <input
                  name="state"
                  value={formData.state}
                  type="text"
                  id="state"
                  required
                  onChange={handleChange}
                />
              </span>
              <span className="contract__section__two__form__input__block one--third">
                <label for="address">Postal/Zip code</label>
                <input
                  name="zipCode"
                  value={formData.zipCode}
                  type="text"
                  id="zipCode"
                  required
                  onChange={handleChange}
                />
              </span>
            </div>
            <span>
              <button onClick={handleNext}>NEXT</button>
            </span>
          </form>
        )}
        {select.select2 && (
          <form>
            <h1>Book Info:</h1>

            <div className="contract__section__two__form__two">
              <span className="contract__section__two__form__input__block">
                <label for="bookTitle">Book Title</label>
                <input
                  name="bookTitle"
                  type="bookTitle"
                  value={formData.bookTitle}
                  id="bookTitle"
                  required
                  onChange={handleChange}
                />
              </span>
            </div>
            <span className="contract__section__two__form__input__block entire--span">
              <label for="synopsis">Synopsis</label>
              <textarea
                required
                name="synopsis"
                id="synopsis"
                value={formData.synopsis}
                onChange={handleChange}
                className="contract__section__two__form__input__block__textarea"
              ></textarea>
            </span>
            <span className="contract__section__two__form__input__block entire--span">
              <label for="outline">Outline</label>
              <textarea
                required
                name="outline"
                id="outline"
                value={formData.outline}
                onChange={handleChange}
                className="contract__section__two__form__input__block__textarea"
              ></textarea>
            </span>
            <span className="contract__section__two__form__input__block">
              <label for="link">
                Is this book on other platforms? If yes, please input the link.
              </label>
              <input
                name="link"
                type="link"
                value={formData.link}
                id="link"
                required
                onChange={handleChange}
              />
            </span>
            <div className="contract__section__two__form__two">
              <span className="contract__section__two__form__input__block one--third">
                <label for="updateRate">Update Rate</label>
                <select
                  required
                  name="updateRate"
                  id="updateRate"
                  value={formData.updateRate}
                  onChange={handleChange}
                  className="modal__apply__input"
                >
                  <option value={null}>--</option>
                  <option value="Option one">Option one</option>
                  <option value="Option two">Option two</option>
                  <option value="Option three">Option three</option>
                  <option value="Option four">Option four</option>
                </select>
              </span>
              <span className="contract__section__two__form__input__block one--third">
                <label for="plannedLength">Planned Length</label>
                <select
                  required
                  name="plannedLength"
                  id="plannedLength"
                  value={formData.plannedLength}
                  onChange={handleChange}
                  className="modal__apply__input"
                >
                  <option value={null}>--</option>
                  <option value="Option one">Option one</option>
                  <option value="Option two">Option two</option>
                  <option value="Option three">Option three</option>
                  <option value="Option four">Option four</option>
                </select>
              </span>
              <span className="contract__section__two__form__input__block one--third">
                <label for="averageWords">Average Chapter Words</label>
                <select
                  required
                  name="avearageWords"
                  id="avearageWords"
                  value={formData.avearageWords}
                  onChange={handleChange}
                  className="modal__apply__input"
                >
                  <option value={null}>--</option>
                  <option value="Option one">Option one</option>
                  <option value="Option two">Option two</option>
                  <option value="Option three">Option three</option>s
                  <option value="Option four">Option four</option>
                </select>
              </span>
              <span className="contract__section__two__form__input__block entire--span">
                <h3>PUSH LINES AND CATCHY DIALOGUES</h3>
                <label for="address">
                  Write upto 10 push lines, catchy moments and dialogues that we
                  can use for social media promotions and app pushes. Do not
                  write sexually suggestive content or slang here, as well as
                  undressing scenes, inappropriate scenes with mentions of
                  alcohol, tobacco or drug consumption. Must be between 200 and
                  3000 words
                </label>
                <textarea className="contract__section__two__form__input__block__textarea2">
                  {" "}
                </textarea>
              </span>
            </div>
            <span>
              <button
                onClick={handleBack}
                style={{
                  background: "white",
                  border: "1px solid #669933",
                  borderRadius: "20px",
                  color: "#669933",
                }}
              >
                BACK
              </button>
              <button onClick={handleSubmit}>SUBMIT</button>
            </span>
          </form>
        )}
      </section>
    </div>
  );
}

export default Contract;
