import { BiWallet } from "react-icons/bi";

import "./Account.dashboard.css";
import image1 from "../../../assets/account.dashboard.png";
import coin from "../../../assets/icons/coin.png";
import image2 from "../../../assets/image10.png";
import { BsStack } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import Modal from "../../../common-components/modal/Modal";

function AccountDashboard() {
  const stories = true;
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState({
    buy: false,
  });
  const closeModal = () => {
    setIsOpen({
      buy: false,
    });
  };
  const handleModalClick = (option) => {
    option === "buy"
      ? setIsOpen((prev) => ({ ...prev, buy: true }))
      : closeModal();
  };

  return (
    <div className="ac__dashboard">
      <section className="ac__dashboard__section__one">
        <div className="ac__dashboard__section__one__div">
          <span>
            <h1>Wallet</h1>
            <BiWallet className="ac__dashboard__section__one__div__icon" />
          </span>
          <span>
            <h1>3000</h1>
            <button onClick={() => handleModalClick("buy")}>
              Top up coins
            </button>
          </span>
        </div>
        <div className="ac__dashboard__section__one__div">
          <span>
            <h1>Total balalnce</h1>
            <BiWallet className="ac__dashboard__section__one__div__icon" />
          </span>
          <span>
            <h1>$800</h1>
            <button>Payment details</button>
          </span>
        </div>
        <div className="ac__dashboard__section__one__div">
          <span>
            <h1>Total revenue</h1>
            <BiWallet className="ac__dashboard__section__one__div__icon" />
          </span>
          <span>
            <h1>$30000</h1>
          </span>
        </div>
        <div className="ac__dashboard__section__one__div">
          <span>
            <h1>Available income</h1>
            <BiWallet className="ac__dashboard__section__one__div__icon" />
          </span>
          <span>
            <h1>$300</h1>
            <button>Withdraw</button>
          </span>
        </div>
      </section>
      <section className="ac__dashboard__section__two">
        <span>
          <p>My stories</p>
          <BsStack className="ac__dashboard__section__two__icon" />
        </span>
        {stories == false && (
          <>
            <img alt="" src={image1} />
            <p>
              Get Started Today, Start Writing and Get your Book to Waiting
              Readers!
            </p>
            <button onClick={() => navigate("/create-novel")}>New Story</button>
          </>
        )}
        {stories == true && (
          <>
            <div className="ac__dashboard__section__two__block">
              <img src={image2} alt="" />
              <div className="ac__dashboard__section__two__block__detail">
                <span className="ac__dashboard__section__two__block__detail__header">
                  <h1>Hidden Interest</h1>
                  <select
                    required
                    name="language"
                    id="language"
                    className="ac__dashboard__section__two__block__detail__select"
                  >
                    <option value={null}>HIDDEN INTEREST</option>
                    <option value="Option one">Option one</option>
                    <option value="Option two">Option two</option>
                    <option value="Option three">Option three</option>
                    <option value="Option four">Option four</option>
                  </select>
                </span>
                <span className="ac__dashboard__section__two__block__detail__content">
                  <article>
                    <p>Collections</p>
                    <h1>0</h1>
                  </article>
                  <article>
                    <p>Views</p>
                    <h1>0</h1>
                  </article>
                  <article>
                    <p>Chapters</p>
                    <h1>9</h1>
                  </article>
                  <article>
                    <p>Words</p>
                    <h1>3033</h1>
                  </article>
                  <article>
                    <p>Status</p>
                    <h1>0</h1>
                  </article>
                </span>
              </div>
            </div>
            <div className="ac__dashboard__section__two__wrap">
              <button
                onClick={() => navigate("/new-chapter")}
                className="ac__dashboard__section__two__wrap__button one"
              >
                New Chapter
              </button>
              <button
                onClick={() => navigate("/contract")}
                className="ac__dashboard__section__two__wrap__button two"
              >
                Apply for Contract
              </button>
              <button
                onClick={() => navigate("/create-novel")}
                className="ac__dashboard__section__two__wrap__button three"
              >
                New Story
              </button>
            </div>
          </>
        )}
      </section>

      <Modal isOpen={isOpen.buy} onClose={closeModal}>
        <div className="ac__modal__buy">
          <h1>TOP UP COINS</h1>
          <p>Coins can be used to unlock chapters and buy gifts for authors.</p>
          <div className="ac__modal__buy__options">
            <div className="ac__modal__buy__option">
              <span>
                <p>100</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>300</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>500</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>1000</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>2000</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>5000</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
            <div className="ac__modal__buy__option">
              <span>
                <p>10000</p>
                <img src={coin} alt="coin" />
              </span>
              <h3>$0.99</h3>
            </div>
          </div>
          <button> PAY NOW </button>
        </div>
      </Modal>
    </div>
  );
}

export default AccountDashboard;
