import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  addFunds,
  fetchWalletBalance,
  withdrawFunds,
} from "../../services/walletServices";
import { useDispatcher } from "../../utils/useDispatcher";

// Define async thunks
export const loadWalletBalance = createAsyncThunk(
  "wallet/loadWalletBalance",
  async (userId, { rejectWithValue }) => {
    try {
      const data = await fetchWalletBalance(userId);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to load wallet balance");
    }
  }
);

export const addWalletFunds = createAsyncThunk(
  "wallet/addWalletFunds",
  async ({ userId, amount }, { rejectWithValue }) => {
    try {
      const data = await addFunds(userId, amount);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to add funds");
    }
  }
);

export const withdrawWalletFunds = createAsyncThunk(
  "wallet/withdrawWalletFunds",
  async ({ userId, amount }, { rejectWithValue }) => {
    try {
      const data = await withdrawFunds(userId, amount);
      return data;
    } catch (error) {
      return rejectWithValue(error.message || "Failed to withdraw funds");
    }
  }
);

export const useLoadWalletBalance = () => useDispatcher(loadWalletBalance);
export const useAddWalletFunds = () => useDispatcher(addWalletFunds);
export const useWithdrawWalletFunds = () => useDispatcher(withdrawWalletFunds);
