import { useState } from "react";

import Footer from "../../components/layout/footer/Footer";
import Header from "../../components/layout/header/Header";
import image1 from "../../../assets/image10.png";
import "./Shelf.css";
import { useNavigate } from "react-router-dom";
import { NOVEL } from "../../../config/data";

function Shelf() {
  const navigate = useNavigate();
  const [select, setSelect] = useState({
    select1: true,
    select2: false,
  });

  const handleSelect = (option) => {
    setSelect((prevSelect) => ({
      ...prevSelect,
      select1: option === "select1",
      select2: option === "select2",
    }));
  };

  return (
    <div className="shelf">
      <div className="shelf__section">
        <Header />
        <div className="shelf__section__one">
          <div className="shelf__section__one__header">
            <h1>Shelf</h1>
            <span>
              <button
                onClick={() => handleSelect("select1")}
                className={`shelf__nav__button${
                  select.select1 ? " active" : ""
                }`}
              >
                Shelf
              </button>
              <button
                onClick={() => handleSelect("select2")}
                className={`shelf__nav__button${
                  select.select2 ? " active" : ""
                }`}
              >
                Reading
              </button>
            </span>
          </div>
          {select.select1 && (
            <div className="shelf__section__one__body">
              {NOVEL.map(function (item, i) {
                // Check if the index is less than six
                if (i < (window.innerWidth >= 768 ? 10 : 6)) {
                  return (
                    <div onClick={() => navigate("/book")}>
                      <img alt="" src={image1} />
                      <h1>{item.title}</h1>
                      <span>
                        <h3>Progress</h3>
                        <h4>1/2380</h4>
                      </span>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          )}
          {select.select2 && (
            <div className="shelf__section__one__body__reading">
              {NOVEL.map(function (item, i) {
                // Check if the index is less than six
                if (i < 10) {
                  return (
                    <div className="shelf__section__one__body__reading__block">
                      <img alt="book cover" src={image1} />
                      <div className="shelf__section__one__body__reading__block__end">
                        <h1>{item.title}</h1>
                        <span className="shelf__section__one__body__reading__block__author">
                          {item.author}
                        </span>
                        <span className="shelf__section__one__body__reading__block__genre">
                          <p>Romance</p>
                          <p>Crime</p>
                        </span>
                        <span className="shelf__section__one__body__reading__block__details">
                          <p>{item.description}</p>
                          <div>
                            <button>ADD TO SHELF</button>
                            <button onClick={() => navigate("/book")}>
                              CONTINUE READING
                            </button>
                          </div>
                        </span>
                        <span className="shelf__section__one__body__reading__block__progress">
                          <p>Progress</p>
                          <p>1/2986</p>
                        </span>
                      </div>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Shelf;
