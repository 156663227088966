import { BiPlus } from "react-icons/bi";
import "./admin-tags.css";
import { FiFilter } from "react-icons/fi";
import { TiExportOutline } from "react-icons/ti";
import { useState } from "react";
import Modal from "../../../common-components/modal/Modal";

const DATA = [
  {
    id: 1,
    tag: "Werewolf",
    categories: "Character",
    action: "...",
  },
  {
    id: 2,
    tag: "Divorce",
    categories: "Setting",
    action: "...",
  },
  {
    id: 3,
    tag: "Wife",
    categories: "Style",
    action: "...",
  },
  {
    id: 4,
    tag: "Travel",
    categories: "Identity",
    action: "...",
  },
  {
    id: 5,
    tag: "Anabel",
    categories: "Sexual )rientation",
    action: "...",
  },
  {
    id: 6,
    tag: "Develop",
    categories: "Sexual Orientation",
    action: "...",
  },
  {
    id: 7,
    tag: "Straight",
    categories: "Setting",
    action: "...",
  },
  {
    id: 8,
    tag: "Love",
    categories: "Setting",
    action: "...",
  },

  // Add more user records as needed
];

function AdminTags() {
  const [formData, setFormData] = useState({
    tag: "",
    category: "",
  });
  const [isOpen, setIsOpen] = useState({
    new: false,
  });
  const closeModal = () => {
    setIsOpen({
      new: false,
    });
  };
  const handleModalClick = (option) => {
    option === "new"
      ? setIsOpen((prev) => ({ ...prev, new: true }))
      : closeModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    console.log(formData);
  };

  const [selectedUsers, setSelectedUsers] = useState([]);

  const handleSelectUser = (id) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.includes(id)
        ? prevSelectedUsers.filter((userId) => userId !== id)
        : [...prevSelectedUsers, id]
    );
  };

  const handleSelectAllUsers = () => {
    if (selectedUsers.length === DATA.length) {
      setSelectedUsers([]);
    } else {
      setSelectedUsers(DATA.map((user) => user.id));
    }
  };

  const handleBulkAction = () => {
    alert(`Performing bulk action on users: ${selectedUsers.join(", ")}`);
  };

  return (
    <div className="ad__novel">
      <section className="ad__novel__sc__one">
        <h1>Tags</h1>
        <span className="ad__novel__sc__one__span">
          <button
            className="ad__novel__sc__one__span__button alt"
            onClick={() => handleModalClick("new")}
          >
            <BiPlus /> Add new
          </button>
        </span>
      </section>

      <section className="ad__novel__sc__three">
        <div className="admin-table">
          <div
            className="admin-table-header"
            style={{
              background: "#f2994a",
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
            }}
          >
            <div className="admin-table-cell">Tag</div>
            <div className="admin-table-cell">Categories</div>
            <div className="admin-table-cell">Action</div>
          </div>
          <div className="admin-table-body">
            {DATA.map((user) => (
              <div key={user.id} className="admin-table-row">
                <div className="admin-table-cell">{user.tag}</div>
                <div className="admin-table-cell">{user.categories}</div>
                <div className="admin-table-cell">{user.action}</div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Modal isOpen={isOpen.new} onClose={closeModal}>
        <div className="admin__modal">
          <h1>New Tag</h1>
          <form className="admin__modal__form">
            <input
              name="tag"
              id="tag"
              type="text"
              placeholder="Enter tag"
              onChange={handleChange}
            />
            <input
              name="category"
              id="category"
              type="text"
              placeholder="Enter category"
              onChange={handleChange}
            />
          </form>
          <button>Add Tag</button>
        </div>
      </Modal>
    </div>
  );
}
export default AdminTags;
