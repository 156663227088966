import { useEffect, useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import "./Home.css";
import Header from "../../components/layout/header/Header";
import Footer from "../../components/layout/footer/Footer";
import ToasterContainer from "../../../common-components/toast/ToasterContainer";
import Meta from "../../../utils/Meta";
import { NOVEL } from "../../../config/data";

import home__hero__image from "../../../assets/home__hero__image.png";
import home__section__two__one from "../../../assets/home__section__two__one.png";
import image11 from "../../../assets/image11.png";
import image12 from "../../../assets/image12.png";
import toastManager from "../../../common-components/toast/ToasterManager";
import { BASE_URL } from "../../../api/axios";
import { useAuth } from "../../../redux/actions/authActions";

function Home() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [blockNumber, setBlockNumber] = useState(
    window.innerWidth >= 768 ? 8 : 6
  );

  const [isOpen, setIsOpen] = useState(true);
  const toggleModal = () => setIsOpen(!isOpen);

  useEffect(() => {
    const handleResize = () => {
      setBlockNumber(window.innerWidth >= 768 ? 8 : 6);
    };

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleButtonClick = () => {
    toastManager.addToast({
      message: "Success message!",
      type: "default",
      duration: 3000,
      onClose: () => console.log("Toast closed"),
    });
  };

  return (
    <div className="home">
      <ToasterContainer />
      <Meta title="Home - BONREADER" />
      <div className="home__main">
        <section className="home__section__one">
          <Header />
          <div className="home__section__one__main">
            <div className="home__section__one__main__start">
              <button>RANK #13</button>
              <h1>ALMOST PERFECT PARTNERS</h1>
              <span>
                <h1>ROMANCE</h1>
                <h1>SHALLY POPY</h1>
              </span>
              <p>
                After recent divorcée Julie has sworn off romance to focus on
                her marketing career, her friends finally convince her to
                cautiously dip a toe back in by trying online dating. To Julie's
                surprise, she seems to hit it off with Blake, a charming
                architect. But over time, red flags appear revealing that Blake
                is still secretly obsessed with and harboring hopes to reunite
                with his ex, Lila
              </p>
              <button>READ NOW</button>
            </div>
            <div className="home__section__one__main__end">
              <img alt="" src={home__hero__image} />
            </div>
          </div>
        </section>
        <section className="home__section__two">
          <div className="home__section__two__start">
            <div className="home__section__two__start__block">
              <h1>Original tales</h1>
              <p>Your gateway to infinite stories</p>
            </div>
            <div className="home__section__two__start__block">
              <h1>Become an author</h1>
              <p>Earn as you create stories</p>
            </div>
            <div className="home__section__two__start__block">
              <h1>Author's manual</h1>
              <p>Step by step manual on how you can write top selling novels</p>
            </div>
          </div>
          <div className="home__section__two__end">
            <h1>Popular Novels</h1>
            <div className="home__section__two__end__section">
              <div className="home__section__two__end__one">
                <div>
                  <img alt="" src={home__section__two__one} />
                  <h1>Almost Perfect Parners</h1>
                  <span>
                    <h3>Romance</h3>
                    <h3>Shally Popy</h3>
                  </span>
                  <p>
                    ter recent divorcée Julie has sworn off romance to focus on
                    her marketing career, her friends finally convince her to
                    cautiously dip a toe back in by trying online dating. To
                    Julie's surprise, she seems to hit it off with Blake, a
                    charming architect. But over time, red flags appear
                    revealing that Blake is still secretly obsessed with and
                    harboring hopes to reunite with his ex, Lila.
                  </p>
                </div>
              </div>
              <div className="home__section__two__end__two">
                {NOVEL.map(function (item, i) {
                  // Check if the index is less than six
                  if (i < (window.innerWidth >= 768 ? 10 : 6)) {
                    return (
                      <div onClick={() => navigate("/book")}>
                        <img alt="" src={item.image} />
                        <h1>{item.title}</h1>
                        <span>
                          <h3>{item.genre}</h3>
                          <h4>{item.read}</h4>
                        </span>
                      </div>
                    );
                  }
                  // If index is greater than or equal to six, return null
                  return null;
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="home__section__three">
          <div className="home__section__three__one">
            <h1>Top Ranked</h1>
            <img alt="" src={image11} />
          </div>
          <div className="home__section__three__two">
            <h1>Rankings</h1>
            <div className="home__section__three__two__trending">
              {NOVEL.map(function (item, i) {
                // Check if the index is less than six
                if (i < 5) {
                  return (
                    <div className="home__section__three__two__trending__block">
                      <h1>{i + 1}</h1>
                      <img src={item.image} />
                      <div>
                        <h1>{item.title}</h1>
                        <span>
                          <h3>{item.genre}</h3>
                          <h4>{item.read}</h4>
                        </span>
                      </div>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          </div>
        </section>
        <section className="home__section__four">
          <span className="home__section__four__heading">
            <h1>Trending</h1>
            <h2>
              See all
              <BsArrowRight className="home__section__four__heading__icon" />
            </h2>
          </span>
          <div className="home__section__four__display">
            {NOVEL.map(function (item, i) {
              // Check if the index is less than six
              if (i < blockNumber) {
                return (
                  <div onClick={() => navigate("/book")}>
                    <img alt="" src={item.image} />
                    <h1>{item.title}</h1>
                    <span>
                      <h3>{item.genre}</h3>
                      <h4>{item.read}</h4>
                    </span>
                  </div>
                );
              }
              // If index is greater than or equal to six, return null
              return null;
            })}
          </div>
        </section>
        <section className="home__section__five">
          <section className="home__section__four__alt">
            <span className="home__section__four__heading">
              <h1>Top Romance</h1>
              <h2>
                See all{" "}
                <BsArrowRight className="home__section__four__heading__icon" />
              </h2>
            </span>
            <div className="home__section__four__alt__display">
              {NOVEL.map(function (item, i) {
                // Check if the index is less than six
                if (i < blockNumber) {
                  return (
                    <div onClick={() => navigate("/book")}>
                      <img alt="" src={item.image} />
                      <div>
                        <h1>{item.title}</h1>
                        <span>
                          <h3>{item.genre}</h3>
                          <h4>{item.read}</h4>
                        </span>
                        <h4>Romance</h4>
                      </div>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          </section>
          <section className="home__section__four">
            <span className="home__section__four__heading">
              <h1>New Arrivals</h1>
              <h2>
                See all{" "}
                <BsArrowRight className="home__section__four__heading__icon" />
              </h2>
            </span>
            <div className="home__section__four__display">
              {NOVEL.map(function (item, i) {
                // Check if the index is less than six
                if (i < blockNumber) {
                  return (
                    <div onClick={() => navigate("/book")}>
                      <img alt="" src={item.image} />
                      <h1>{item.title}</h1>
                      <span>
                        <h3>{item.genre}</h3>
                        <h4>{item.read}</h4>
                      </span>
                    </div>
                  );
                }
                // If index is greater than or equal to six, return null
                return null;
              })}
            </div>
          </section>
        </section>
        <section className="home__section__four">
          <span className="home__section__four__heading">
            <h1>Completed Novels</h1>
            <h2>
              See all{" "}
              <BsArrowRight className="home__section__four__heading__icon" />
            </h2>
          </span>
          <div className="home__section__four__display">
            {NOVEL.map(function (item, i) {
              // Check if the index is less than six
              if (i < blockNumber) {
                return (
                  <div onClick={() => navigate("/book")}>
                    <img alt="" src={item.image} />
                    <h1>{item.title}</h1>
                    <span>
                      <h3>{item.genre}</h3>
                      <h4>{item.read}</h4>
                    </span>
                  </div>
                );
              }
              // If index is greater than or equal to six, return null
              return null;
            })}
          </div>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
