import { CgFileAdd } from "react-icons/cg";
import "./Account.stories.css";
import { FcPrivacy } from "react-icons/fc";
import { BiTrash } from "react-icons/bi";
import { CiSettings } from "react-icons/ci";
import image1 from "../../../assets/image10.png";
import { useNavigate } from "react-router-dom";

function AccountStories() {
  const navigate = useNavigate();

  return (
    <div>
      <section className="ac__stories__section__one">
        <button onClick={() => navigate("/create-novel")}>
          <CgFileAdd /> Add stories
        </button>
      </section>
      <section className="ac__stories__section__two">
        <div className="ac__stories__section__two__header">
          <h1 className="ac__stories__section__two__header__stories">
            Stories
          </h1>
          <h1 className="ac__stories__section__two__header__state">State</h1>
          <h1 className="ac__stories__section__two__header__chapter">
            Chapter
          </h1>
          <h1 className="ac__stories__section__two__header__word">Words</h1>
          <h1 className="ac__stories__section__two__header__collection">
            Collections
          </h1>
          <h1 className="ac__stories__section__two__header__words">Action</h1>
        </div>
        <div className="ac__stories__section__two__entry">
          <h1 className="ac__stories__section__two__entry__stories">
            <img
              src={image1}
              alt=""
              className="ac__stories__section__two__entry__stories__img"
            />
            Hidden Interest
          </h1>
          <h1 className="ac__stories__section__two__entry__state">Draft</h1>
          <h1 className="ac__stories__section__two__entry__chapter">13</h1>
          <h1 className="ac__stories__section__two__entry__word">12044</h1>
          <h1 className="ac__stories__section__two__entry__collection">2</h1>
          <h1 className="ac__stories__section__two__entry__words">
            <CiSettings /> <FcPrivacy />
            <BiTrash />
          </h1>
        </div>
      </section>
    </div>
  );
}

export default AccountStories;
