import api from "../api/axios";

// Fetch all books
export const fetchBooks = async () => {
  try {
    const response = await api.get("/books");
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Fetch a single book by ID
export const fetchBook = async (bookId) => {
  try {
    const response = await api.get(`/books/${bookId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add a new book
export const addBook = async (bookData) => {
  try {
    const response = await api.post("/books", bookData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Update an existing book
export const updateBook = async (bookId, bookData) => {
  try {
    const response = await api.put(`/books/${bookId}`, bookData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Delete a book
export const deleteBook = async (bookId) => {
  try {
    const response = await api.delete(`/books/${bookId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
