import { useState } from "react";
import { FaHome } from "react-icons/fa";
import { BiMenu, BiSearch } from "react-icons/bi";
import { BsTranslate } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import { MdClose } from "react-icons/md";
import { GiPoliceBadge } from "react-icons/gi";

import "./Header.css";
import "../../../../common-components/modal-children-style/modal-login.css";
import "../../../../common-components/modal-children-style/modal-signup.css";
import logo from "../../../../assets/icons/logo.png";
import profileImage from "../../../../assets/profile-image.jpg";
import Modal from "../../../../common-components/modal/Modal";
import google__icon from "../../../../assets/icons/google.png";
import facebook__icon from "../../../../assets/icons/facebook.png";
import {
  useAuth,
  useLogin,
  useLogout,
  useSignUp,
} from "../../../../redux/actions/authActions";
import { VscAccount } from "react-icons/vsc";

function Header() {
  const signUp = useSignUp();
  const login = useLogin();
  const logout = useLogout();
  const location = useLocation();

  //Redux
  const auth = useAuth();

  //State and context declarations
  const [menu, setMenu] = useState(false);
  const [isOpen, setIsOpen] = useState({
    signin: false,
    signup: false,
  });
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    first_name: "",
    last_name: "",
    gender: "Male",
    re_password: "",
  });

  //Hooks definitions
  const navigate = useNavigate();

  const closeModal = () => {
    setIsOpen({
      signin: false,
      signup: false,
    });
  };

  const handleAuthClick = (option) => {
    option === "signin"
      ? setIsOpen((prev) => ({ ...prev, signup: false, signin: true }))
      : setIsOpen((prev) => ({ ...prev, signup: true, signin: false }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    signUp(formData);

    console.log(formData);

    closeModal();
    setMenu(false);
  };

  const handleLogin = (e) => {
    e.preventDefault();

    login(formData);

    closeModal();
    setMenu(false);
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="header">
      <img
        className="header__logo"
        src={logo}
        alt=""
        onClick={() => navigate("/")}
      />
      <ul className="header__ul">
        <li
          className={location.pathname === "/" ? "header__nav__active" : ""}
          onClick={() => {
            navigate("/");
          }}
        >
          <FaHome className="header__icons" /> Home
        </li>
        <li
          className={
            location.pathname === "/genre" ? "header__nav__active" : ""
          }
          onClick={() => {
            navigate("/genre");
          }}
        >
          <FaHome className="header__icons" /> Genre
        </li>
        <li
          className={
            location.pathname === "/ranking" ? "header__nav__active" : ""
          }
          onClick={() => {
            navigate("/ranking");
          }}
        >
          <FaHome className="header__icons" /> Ranking
        </li>
        <li
          className={
            location.pathname === "/create" ? "header__nav__active" : ""
          }
          onClick={() => {
            navigate("/create");
          }}
        >
          <FaHome className="header__icons" /> Create
        </li>
        <li
          className={
            location.pathname === "/shelf" ? "header__nav__active" : ""
          }
          onClick={() => navigate("/shelf")}
        >
          <FaHome className="header__icons" />
          Shelf
        </li>
      </ul>
      <div className="header__end">
        <BiSearch className="header__icons" />
        <div>
          <BsTranslate />
          <p>English</p>
        </div>
        {auth.user ? (
          <h3 className="header__end__sign" onClick={handleLogout}>
            Sign out
          </h3>
        ) : (
          <h3
            className="header__end__sign"
            onClick={() => handleAuthClick("signin")}
          >
            Sign in
          </h3>
        )}

        <span
          className="header__end__account"
          onClick={() => {
            navigate("/account-dashboard");
          }}
        >
          {auth.user ? (
            <img alt="profile" src={profileImage} />
          ) : (
            <VscAccount className="header__guest__profile" />
          )}

          <GiPoliceBadge className="header__end__account__badge" />
        </span>
      </div>
      <BiMenu
        className="header__menu"
        onClick={() => {
          setMenu(true);
        }}
      />

      <section className={`header__sidebar${menu ? " active" : ""}`}>
        <span className="header__sidebar__head">
          <span
            className="header__sidebar__account"
            onClick={() => {
              navigate("/account-dashboard");
            }}
          >
            {auth.user ? (
              <img alt="profile" src={profileImage} />
            ) : (
              <VscAccount className="header__guest__profile" />
            )}
            <GiPoliceBadge className="header__end__account__badge" />
          </span>

          <MdClose
            className="header__sidebar__head__close"
            onClick={() => {
              setMenu(false);
            }}
          />
        </span>
        <div className="header__sidebar__body">
          <ul className="header__sidebar__ul">
            <li
              className={location.pathname === "/" ? "header__nav__active" : ""}
              onClick={() => {
                navigate("/");
                setMenu(false);
              }}
            >
              <FaHome className="header__sidebar__icons" /> Home
            </li>
            <li
              className={
                location.pathname === "/genre" ? "header__nav__active" : ""
              }
              onClick={() => {
                navigate("/genre");
                setMenu(false);
              }}
            >
              <FaHome className="header__sidebar__icons" /> Genre
            </li>
            <li
              className={
                location.pathname === "/ranking" ? "header__nav__active" : ""
              }
              onClick={() => {
                navigate("/ranking");
                setMenu(false);
              }}
            >
              <FaHome className="header__sidebar__icons" /> Ranking
            </li>
            <li
              className={
                location.pathname === "/create" ? "header__nav__active" : ""
              }
              onClick={() => {
                navigate("/create");
                setMenu(false);
              }}
            >
              <FaHome className="header__sidebar__icons" /> Create
            </li>
            <li
              className={
                location.pathname === "/shelf" ? "header__nav__active" : ""
              }
              onClick={() => {
                navigate("/shelf");
                setMenu(false);
              }}
            >
              <FaHome className="header__sidebar__icons" />
              Shelf
            </li>
          </ul>
          {auth.user ? (
            <button className="header__sidebar__sign" onClick={handleLogout}>
              Sign out
            </button>
          ) : (
            <button
              className="header__sidebar__sign"
              onClick={() => handleAuthClick("signin")}
            >
              Sign in
            </button>
          )}
        </div>
      </section>

      {/* SIGNIN MODAL */}

      <Modal isOpen={isOpen.signin} onClose={closeModal}>
        <div className="modal__login__container">
          <img src={logo} alt="" />
          <h1>You are welcome</h1>
          <div className="modal__login__container__social__signin">
            <button>
              <img src={google__icon} alt="" />
              Sign in with Google
            </button>
            <button>
              <img src={facebook__icon} alt="" />
              Sign in with Facebook
            </button>
          </div>

          <form
            className="modal__login__container__form"
            onSubmit={handleLogin}
          >
            <input
              name="email"
              type="email"
              value={formData.email}
              important
              placeholder="Enter your email address"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="password"
              type="password"
              value={formData.password}
              important
              placeholder="Enter your password"
              onChange={(e) => handleChange(e)}
            />
            <button type="submit">Sign In</button>
            <p>
              Forgot password? <span>Reset Here</span>
            </p>
          </form>
          <p>
            Don't have an account?
            <span onClick={() => handleAuthClick("signup")}>Sign up</span>
          </p>
        </div>
      </Modal>

      {/* SIGNUP MODAL */}

      <Modal isOpen={isOpen.signup} onClose={closeModal}>
        <div className="modal__signup__container">
          <img src={logo} alt="" />
          <h1>Signup</h1>
          <div className="modal__signup__container__social__signin">
            <button>
              <img src={google__icon} alt="" />
              Sign in with Google
            </button>
            <button>
              <img src={facebook__icon} alt="" />
              Sign in with Facebook
            </button>
          </div>

          <form
            className="modal__signup__container__form"
            onSubmit={handleSubmit}
          >
            <input
              name="first_name"
              type="text"
              important
              placeholder="Enter First Name"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="last_name"
              type="text"
              important
              placeholder="Enter Last Name"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="email"
              type="email"
              important
              placeholder="Enter your email address"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="password"
              type="password"
              important
              placeholder="Enter your password"
              onChange={(e) => handleChange(e)}
            />
            <input
              name="re_password"
              type="password"
              important
              placeholder="Confirm password"
              onChange={(e) => handleChange(e)}
            />
            <button type="submit">Sign up</button>
          </form>
          <p>
            Have an account?{" "}
            <span onClick={() => handleAuthClick("signin")}>Sign in</span>
          </p>
        </div>
      </Modal>
    </div>
  );
}

export default Header;
