// services/walletService.js
import api from "../api/axios";

// Fetch wallet balance
export const fetchWalletBalance = async (userId) => {
  try {
    const response = await api.get(`/wallet/${userId}/balance`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Add funds to wallet
export const addFunds = async (userId, amount) => {
  try {
    const response = await api.post(`/wallet/${userId}/add`, { amount });
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Withdraw funds from wallet
export const withdrawFunds = async (userId, amount) => {
  try {
    const response = await api.post(`/wallet/${userId}/withdraw`, { amount });
    return response.data;
  } catch (error) {
    throw error;
  }
};
