import image10 from "../assets/image10.png";

export const NOVEL = [
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
  {
    title: "Almost Perfect Partner",
    author: "JULIE WILLIAMS",
    genre: "Romance",
    image: image10,
    rating: 4.5,
    read: "11.4k",
    description:
      "After recent divorcée Julie has sworn off romance to focus on her marketing career, her friends finally convince her to cautiously dip a toe back in by trying online dating. To Julie's surprise, she seems to hit it off with Blake, a charming architect. But over time, red flags appear revealing that Blake is still secretly obsessed with and harboring hopes to reunite with his ex, Lila",
  },
];
