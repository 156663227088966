import "./AccountTransactionHistory.css";

function AccountTransactionHistory() {
  return (
    <div>
      <section className="ac__transaction__section__one">
        <h1>Transaction History</h1>
        <span className="ac__transaction__section__one__nav">
          <h3>Income History</h3>
          <h3>Withdrawal History</h3>
        </span>
        <hr />
        <div className="ac__transaction__section__two">
          <div className="ac__stories__section__two__header">
            <h1 className="ac__stories__section__two__header__stories">S/N</h1>
            <h1 className="ac__stories__section__two__header__state">Amount</h1>
            <h1 className="ac__stories__section__two__header__chapter">
              Period
            </h1>
            <h1 className="ac__stories__section__two__header__word">
              Description
            </h1>
            <h1 className="ac__stories__section__two__header__collection">
              Status
            </h1>
            <h1 className="ac__stories__section__two__header__words">Action</h1>
          </div>
          <div className="ac__stories__section__two__entry">
            <h1 className="ac__stories__section__two__entry__stories">1.</h1>
            <h1 className="ac__stories__section__two__entry__state">200</h1>
            <h1 className="ac__stories__section__two__entry__chapter">
              Jan - Feb 2024
            </h1>
            <h1 className="ac__stories__section__two__entry__word">
              Novel Royalty
            </h1>
            <h1 className="ac__stories__section__two__entry__collection">
              Approved
            </h1>
            <h1 className="ac__stories__section__two__entry__words">...</h1>
          </div>
        </div>
      </section>
    </div>
  );
}

export default AccountTransactionHistory;
