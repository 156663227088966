import { useState } from "react";
import Footer from "../../components/layout/footer/Footer";
import Header from "../../components/layout/header/Header";
import "./new-chapter.css";
import TextEditor from "../../components/ui/text-editor/TextEditor";

function NewChapter() {
  const [title, setTitle] = useState("");

  return (
    <div className="new__chapter__bg">
      <div className="new__chapter">
        <Header />
        <section className="new__chapter__section__one">
          <section className="new__chapter__nav">
            <button>New Chapter</button>
            <div>
              <h3>Move Fast, Don’t Snooze</h3>
              <p>303 Words | 22-12-2023 | 12:00:13</p>
            </div>
            <div>
              <h3>Untittled Chapter</h3>
              <p>Unpublished</p>
            </div>
          </section>
          <section className="new__chapter__body">
            <div className="new__chapter__body__header">
              <input
                type="text"
                placeholder="Chapter Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="new__chapter__body__header__chaptertitle"
              />
              <h3>...</h3>
            </div>
            <div className="new__chapter__body__content">
              <TextEditor />
            </div>
          </section>
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default NewChapter;
